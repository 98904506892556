import React, { Component } from 'react';
import Form from "./form";

class Main extends Component {
  render() {
    return (
      <main>
        <section id="nosotros" className="intro">
            <h2>Nosotros</h2>
            <div>
             <p> Somos una empresa dedicada al desarrollo de proyectos de tecnología. Hemos asesorado a una gran cantidad de Pymes del país, tambien hemos apoyado a diversas empresas de desarrollo de software del mercado a implementar e integrar sus proyectos de documentos Electrónicos. 
             < br/> < br/>
             < br/> El SII nos reconoce y nos respalda como Empresa asesora de Documentos Electrónicos. < br/> 
             < br/> También desarrollados proyectos a medida, en diversas plataformas, según la necesidad de tu negocio < br/>
             < br/> Entendemos tus necesidades, nuestro esfuerzo se enfoca en entregarte la mejor solución.< br/></p>
            </div>
        </section>

        <div id="servicios" className="services">
          <div className="service-one">
          <p className="service-icon"><i className="fas fa-file-pdf"></i></p>
          <p className="service-title">Boleta Electrónica</p>
          <p className="service-titledet">Boleta Afecta Electrónica</p>
          <p className="service-titledet">Boleta Exenta Electrónica</p>
          <p className="service-titledet">Libro de Boleta Electrónica</p>
          <p className="service-titledet">Informe de Consumo de Folios</p>
        </div>

        <div className="service-one">
          <p className="service-icon"><i className="fas fa-file-pdf"></i></p>
          <p className="service-title">Factura Electrónica</p>
          <p className="service-titledet">Factura Afecta y Exenta Electrónica</p>
          <p className="service-titledet">Documentos Nacionales</p>
          <p className="service-titledet">Documentos de Exportación</p>
          <p className="service-titledet">Certificación ante el SII</p>
          <p className="service-titledet">Cesión de documentos</p>
          <p className="service-titledet">Emisión directa al SII</p>
          <p className="service-titledet">Integración con Sistemas</p>
        </div>

        <div className="service-two">
          <p className="service-icon"><i className="fas fa-dollar-sign"></i></p>
          <p className="service-title">Punto de Venta</p>
          <p className="service-titledet">Documentos Electrónicos</p>
          <p className="service-titledet">Documentos Manuales</p>          
          <p className="service-titledet">Compras</p>
          <p className="service-titledet">Controla de Stock</p>
          <p className="service-titledet">Informes de gestión</p>
          <p className="service-titledet">Se adapta a sus necesidades</p>
        </div>

        <div className="service-three">
          <p className="service-icon"><i className="fas fa-code"></i></p>
          <p className="service-title">Proyectos</p>
          <p className="service-titledet">Desarrollo de Proyectos a Medida</p>        
          <p className="service-titledet">Según necesidades</p>        
          <p className="service-titledet">Implementamos diversas Tecnologías</p>                
          <p className="service-titledet">Años de Experiencia en Proyectos TI </p>                
        </div>

        </div>
        
       {  /*<div id="clientes" className="gallery">
        <h4>Nuestros Clientes</h4>    
          <div className="gallery-item-one"></div>
          <div className="gallery-item-two"></div>
          <div className="gallery-item-three"></div>
          <div className="gallery-item-four"></div>
          <div className="gallery-item-five"></div>
          <div className="gallery-item-six"></div>        
          <div className="gallery-item-seven"></div>  
          <div className="gallery-item-eight"></div> 
          <iframe  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.8313958880376!2d-70.67733908471645!3d-33.42763980372824!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c43607e6a1bd%3A0x7f616d2aa68ed8a8!2sLibertad+1473%2C+Santiago%2C+Regi%C3%B3n+Metropolitana!5e0!3m2!1ses!2scl!4v1524425662086" >               
        </div>
       */}
        <section id="mapa">
              <div className="map">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4075.8212217787764!2d-70.67752439233115!3d-33.42914477805404!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9662c449f8c7f833%3A0x7139ac0d30fa4263!2sLibertad%201403%2C%20Santiago%2C%20Regi%C3%B3n%20Metropolitana!5e0!3m2!1ses!2scl!4v1664986194979!5m2!1ses!2scl">              
                  </iframe>
              </div>                   
        </section>
         
        <h3>Contáctanos solucionesingemaf@gmail.com</h3>  

        <section id="contacto">
            <h2>Como podemos Ayudarte </h2>      
            <Form />          
        </section> 
       
      </main>
    );
  }
}

export default Main;