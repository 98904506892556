import React, { Component } from 'react';
import { Link, animateScroll as scroll } from "react-scroll";
class Nav extends Component {
  render() {
    return (
      <nav>
          <ul>
              <li className="logo">Soluciones Ingemaf</li>
              <li className="logo"> <span>solucionesingemaf@gmail.com </span></li>
          </ul>
          <ul>
            <Link activeClass="active" to="inicio" spy={true} smooth={true} offset={0} duration= {500}>Inicio</Link>
            <Link activeClass="active" to="nosotros" spy={true} smooth={true} offset={0} duration= {500}>Nosotros</Link>
            <Link activeClass="active" to="servicios" spy={true} smooth={true} offset={0} duration= {500}>Servicios</Link>
            <Link activeClass="active" to="clientes" spy={true} smooth={true} offset={0} duration= {500}>Clientes</Link>
            <Link activeClass="active" to="mapa" spy={true} smooth={true} offset={0} duration= {500}>Contacto</Link>          
          </ul>
      </nav>
    );
  }
}
export default Nav;
