import React, { Component } from 'react';
import Nav from "./nav";

class Header extends Component {
  render() {
    return (     
      <header>
      <Nav/>
        <div id="inicio" className="head">
            <h1>Boleta Factura< br/>Electrónica< br/>para tu empresa</h1>
            <div>
              <p>ASESORIA DE PROFESIONALES EXPERTOS. </p>              
            </div>
        </div>
       
      </header>
      // <div><a className="contact" href="#">Contáctamos</a></div>
    );
  }
}

export default Header;
