import { useRef } from 'react'
import React, { Component } from 'react';
import Swal from 'sweetalert2'
import axios from 'axios';
//const axios = require('axios').default;

//const nameRef = useRef();

var name_ = "";
//var nameRef 

//this.state.name= "1";

/*function clearData() {
  let username = ""; 
  this.setState({
      name: username
  });

  return true;
}*/

class Form extends Component {
  constructor(){
      super();
      this.state = {
          name : "",
          email : "",
          message : "",
          formError: false,
      }  

  };

  getName = (e) =>{
    console.log('Rescato Nombre');
    let username = e.target.value; 
    this.setState({
        name: username
    });
  }

  setName = (e) =>{
    let username = ""; 
    this.setState({
        name: username
    });
  }

  getEmail = (e) =>{
    console.log('Rescato Mail');
    let userEmail = e.target.value; 
    //the most important thing is that we use a RegEx
    //in order to manage the input of the email
    //at least we can get a some what valid email
    if(userEmail.match(/^([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/)){
      this.setState({
         email: userEmail
    });
      }else{
        this.setState({
          email: ""
      });
      console.log("Error Email con expresión Incorrecta");
    }
  }

  getDescription = (e) =>{
    console.log('Rescato Glosa');
    let userMessage = e.target.value; 
    this.setState({
        message: userMessage
    });
  }

  submitForm = (e) =>{
    e.preventDefault();
    //console.log('Envio Mail(0)');

   // alert('inicio');
    if (this.state.name === "" || this.state.email === "" || this.state.message === "" ){
       console.log('Error de datos Vacios');
        this.setState({
           formError: true
                    })
      return false;
    } else {
       console.log('Envio Mail(1)');
    /*   var email = this.state.email
       var name = this.state.name
       var message = this.state.message*/
/* Envio MAIL */

let sMAIL = 'http://ingemaf.cl/sendmail.php?send=ok&nombre='+this.state.name+'&mail='+this.state.email+'&texto='+this.state.message;
console.log('Link:'+sMAIL);
 
axios.get(sMAIL)
  .then(response => {
    alert(response);
  })
  .catch(error => {
    alert('Error:'+error);
  })

/**fetch(sMAIL,
 { method: 'GET',
 headers: {
  'Content-Type': 'application/json'
  },
   mode: 'cors', // <---
   cache: 'default'
})
.then(function(response) {
  alert(response);
 // return response.json()
})*/

/*fetch(sMAIL, {
  method: 'GET',
  cache: 'no-cache',
  credentials: 'same-origin',
  headers: {
      'Content-Type': 'application/json'
  },
    }).then(function(muutuja){
  alert(muutuja);
       // document.getElementById('v?ljund').innerHTML = JSON.stringify(muutuja);
    });*/

/*const axios = require('axios');

axios.get(sMAIL)
  .then(response => {
    console.log(response.data.url);
    console.log(response.data.explanation);
  })
  .catch(error => {
    console.log(error);
  });*/

/*(async () => {
  try {
    var response = await fetch(sMAIL);
    alert(response);
    var data = await response.json();
    console.log(data);
  } catch (e) {
    console.log('Booo');
  }
})();*/

/*fetch(sMAIL)
  .then(function (response) {
    return response.json();
  })
  .then(function (data) {
    console.log(data);
  })
  .catch(function () {
    console.log('Booo');
  });*/

  /*  fetch(sMAIL)
      .then((response) => {
        alert(response);
      })
      .catch((error) => {
        console.log('Consola:'+sMAIL);
        alert(error);
      });
*//*
   fetch(sMAIL, {
        method: "POST",
        headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "text/plain"
        },"mode" : "no-cors"// mode: 'no-cors'
       // body: JSON.stringify(myVar)
        //body: {"id" : document.getElementById('saada').value}
    }).then(function(muutuja){
   alert('Aca error:'+muutuja);
       // document.getElementById('väljund').innerHTML = JSON.stringify(muutuja);
    });*/

   /* try {
      const response = await fetch('https://randomuser.me/api/', {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`Error! status: ${response.status}`);
      }
  
      const result = await response.json();
      return result;
    } catch (err) {
      console.log(err);
    }
  }*/

/* Grabar data*/
      this.setState({
            name: "",
            email: "",
            message: "",
            formError: false
         })

         console.log('Datos:' +this.state.name +'-'+ this.state.email +'-'+ this.state.message);

         var data = {
              name: this.state.name,
              email: this.state.email,
              message: this.state.message
          }

          //addcontact
          name_ = ""
          console.log('Call insertMail' );         
         fetch('https://ingemaf.cl/emp.php', { 
                 method: 'POST', 
                 headers:{ 'Content-Type': 'application/json',
                          'Access-Control-Allow-Origin':'*' },               
                 body: JSON.stringify(data) 
                }
              )
         .then(function(res){ 
              if (res.status == 200) {                
                Swal.fire({
                 // title: 'Usuario!',
                  text: 'Datos grabados con Exito',
                  icon: 'success',
                  confirmButtonText: 'ok'
                   })

                   name_ = "ok";                     
                   if (name_=== "ok"){ 
                                      
                   /*  var elementoN = document.getElementById("name")
                     alert( elementoN.value )
                     var elementoE = document.getElementById("email")
                     alert( elementoE.value )
                     var elementoM = document.getElementById("message")
                     alert( elementoM.value )*/

                     var elementoN = document.getElementById("name")
                     elementoN.value = ""
                     var elementoE = document.getElementById("email")
                     elementoE.value = ""
                     var elementoM = document.getElementById("message")
                     elementoM.value = ""
                     //alert("ok");
                 //     window.scrollTo(0, 0);
                    }

                    
              }

              if (res.status >= 400) {
                  throw new Error("Bad response from server");
              }
            })
          .catch(function(err) {
              alert('Error:(1)'+err);
              console.log("Error Fetch:" + err)
           });
      }
    }
    
  render() {
    return (
        <form>
              {this.state.formError &&
                <p className="error">
                    Por favor llena todos los campos
                </p>
              }

             {/*  <p>Por favor llena todos los campos ...</p>*/}

              <div>
                <label htmlFor="name">Tu Nombre</label>
                <input type="text" id="name" name="name" placeholder="" onChange={this.getName} />
              </div>

              <div>
                <label htmlFor="email">Tu Email</label>
                <input type="email" id="email" name="email" placeholder="" onChange={this.getEmail} />
              </div>

              <div>
                <label htmlFor="name">Tu Inquietud</label>
                <textarea id="message" onChange={this.getDescription} maxLength="450"></textarea>
              </div>
              <div>
                <p>Te contactaremos a la brevedad ...</p>
                <input type="submit" name="submit" value="Enviar" onClick= {this.submitForm} />
              </div>
              
            </form>
     );
  }

 }              //insertMail = (e) =>{
  //  insertMail(data){
  //  console.log('insertMail(0)');

 // }



export default Form;
